import React, { useEffect, useRef } from "react";
import axios from 'axios';

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import L, {LatLngBounds} from "leaflet";
import "leaflet.markercluster";

const LeafletMaps = ({ job, containerId, ajlu, activeLeadTab, data }) => {
  const mapRef = useRef(null);
  const markerClusterRef = useRef(null);

  useEffect(() => {
    mapRef.current = L.map(containerId).setView([0, 0], 3); 

    L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png", {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      maxZoom: 18,
      minZoom: 1,
    }).addTo(mapRef.current);

    markerClusterRef.current = L.markerClusterGroup({
      spiderfyOnMaxZoom: false, // Disable spiderfication on max zoom
    })
    
    return () => {
      markerClusterRef.current.remove();
      mapRef.current.remove();
    }
  }, [])

  useEffect(() => {
    async function fetchData() {
      return await new Promise(async(resolve, reject) => {
        try {
          const response = await axios.post('/mapData', { jobid: job });
          const apiData = response.data;
          const newMarkers = apiData.map((item) => ({
            id: item.id,
            position: [item.latitude, item.longitude],
            content: item.name,
          }));
          resolve(newMarkers);
        } catch (err) {
          console.error('Error fetching data:', err);
        }
      })
    }

    try {
      fetchData()
      .then((markers) => {
        if (markers && markers.length > 0) {
          // Clear existing layers
          markerClusterRef.current.clearLayers();
        
          const customIcon = new L.Icon({
            iconUrl: 'map-pin.png', 
            iconSize: [20, 20],
            iconAnchor: [16, 32],
            popupAnchor: [0, -32],
          });
          
          const bounds = new LatLngBounds();
          markers.forEach((markerData) => {
            const marker = L.marker(markerData.position, { icon: customIcon });
            marker.bindTooltip(`${markerData.content}`);
            markerClusterRef.current.addLayer(marker);
            bounds.extend(markerData.position);
          });

          mapRef.current.addLayer(markerClusterRef.current);
          // mapRef.current.fitBounds(bounds);
          // Highlight the marker if condition is met
          let targetMarker = markers[0].position; // Default to the first marker's position

          if (containerId === 'tabMapContainer' && data.length > 0) {
            const matchingMarker = markers.find(marker => marker.id === data[0].id);
            if (matchingMarker) {
              targetMarker = matchingMarker.position;
              
            }
            mapRef.current.setView(targetMarker, 15);
          }else{
            mapRef.current.setView(targetMarker, 3);
          }
          
        }
      })
    } catch (err) {
      // console.log(err)
    }

  }, [job, ajlu, containerId, data])

  useEffect(() => {
    mapRef?.current.invalidateSize();
  }, [activeLeadTab]);
  
  return <div id={containerId} style={{ height: "100%" }} />;
};

export default LeafletMaps;