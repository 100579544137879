import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import People from './people';
import LeadDetails from './leaddetails';
import LeadTabs from './leadtabs';
import PeopleFilters from './pfilters';

function Pmain(props) {
  const SIDE_MIN_WIDTH = '40px';
  const SIDE_MAX_WIDTH = '240px';
  // const RESULTS_PER_UNIT = 120;
  const peopleRef = useRef(null);
  const leadDetailsRef = useRef(null);
  const [leadDetailsWidth, setLeadDetailsWidth] = useState(0);

  const [sbsplit, setSbsplit] = useState([SIDE_MAX_WIDTH, 'auto']);
  const [hsplit, setHsplit] = useState(["50%", 'auto']);
  const [vsplit, setVsplit] = useState(["60%", 'auto']);
  const thisResizerSize = 5;

  const [thisProps] = useState(props);

  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSidebar = () => { 
    setShowSidebar(!showSidebar); 
    setSbsplit(showSidebar ? [SIDE_MIN_WIDTH, 'auto'] : [SIDE_MAX_WIDTH, 'auto']);
  };

  const [showGridView, setShowGridView] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);

  const [updatingPeopleData, setUpdatingPeopleData] = useState(false);
  const passUpdatingPeopleData = (data) => (setUpdatingPeopleData(data));

  const [peopleData, setPeopleData] = useState([]);
  const updatePeopleData = (data) => { setPeopleData(data) };

  const [peopleFilter, setPeopleFilter] = useState({});
  const passPeopleFilter = (data) => { setPeopleFilter(data) };

  const toggleView = () => {
    setShowGridView(!showGridView)
  }

  const tabRefresh = () => {
    setForceRefresh(!forceRefresh)
  }

  useEffect(() => {
    peopleRef.current.toggleView(!showGridView);
  }, [showGridView]);

  useEffect(() => {
    if (!leadDetailsRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setLeadDetailsWidth(leadDetailsRef.current.offsetWidth);
    });
    resizeObserver.observe(leadDetailsRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    peopleRef.current.fetchData()
  }, [forceRefresh]);

  useEffect(() => {
    peopleRef.current.applyFilter(peopleFilter)
  }, [peopleFilter])

  return (
    <React.Fragment>
      <SplitPane allowResize={false} sizes={sbsplit} onChange={setSbsplit}>
        <div className='w-100 border shadow'>
          <div className='w-100 p-2 d-flex align-items-center bg-primary text-white shadow' style={{ height: '40px' }}>
            <span className={`w-100 fs-5 fw-bold`}>{`${showSidebar ? 'Filter' : ''}`}</span>
            <Button size="lg" className={`m-0 p-0 border border-primary`} onClick={toggleSidebar}>
              <i className={`bi ${showSidebar ? 'bi-caret-left-fill' : 'bi-caret-right-fill'}`}></i>
            </Button>
          </div>
          <div className={`w-100 p-2 side-panel overflow-auto ${showSidebar ? 'visible' : 'invisible'}`}>
            <PeopleFilters ppf={passPeopleFilter} />
          </div>
        </div>
        <div className='w-100 h-100 d-flex flex-column overflow-hidden'>
          <Tab.Container defaultActiveKey="people">
            <Nav variant='tabs' className='fs-6 border' >
              <Nav.Item><Nav.Link eventKey="people">People{updatingPeopleData ? (<React.Fragment>&nbsp;<i className="fa fa-spinner fa-pulse text-white"></i></React.Fragment>) : ''}</Nav.Link></Nav.Item>
              <Nav.Item className='ms-auto'>
                <Button title="Grid / Cards" className={`btn btn-primary mx-1 float-right`} onClick={toggleView}><i className={`bi ${showGridView ? 'bi-list' : 'bi-grid'}`}></i></Button>
                <Button title="Refresh" className={`btn btn-primary mx-1 float-right`} onClick={tabRefresh}><i className={`bi bi-arrow-repeat`}></i></Button>
              </Nav.Item>
              <Nav.Item>
                <div style={{width: `${leadDetailsWidth}px`}}></div>
              </Nav.Item>
            </Nav>
            <Tab.Content className='w-100' style={{ height: "calc(100% - 34px)" }} animation="true" mountOnEnter={true} unmountOnExit={true} >
              <Tab.Pane className='w-100 h-100' eventKey="people" title="People">
                <SplitPane split="horizontal" sizes={hsplit} resizerSize={thisResizerSize} onChange={setHsplit}>
                  <SplitPane sizes={vsplit} resizerSize={thisResizerSize} onChange={setVsplit}>
                    <Card className={`w-100 h-100 overflow-auto`}>
                      <People ref={peopleRef} data={updatePeopleData} upd={passUpdatingPeopleData} loggedInUserId={thisProps.uid} />
                    </Card>
                    <Card ref={leadDetailsRef} className="w-100 h-100 overflow-auto">
                      <LeadDetails data={peopleData} view="people" />
                    </Card>
                  </SplitPane>
                  <Card className="w-100 h-100 overflow-auto">
                  <LeadTabs data={peopleData} view="people" />
                  </Card>
                </SplitPane>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </SplitPane>
    </React.Fragment >
  )
}

export default Pmain;
