import React, { useEffect, useState } from 'react';
import { LoginSocialMicrosoft, LoginSocialGoogle } from 'reactjs-social-login'
import { decodeToken } from "react-jwt";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useLocation } from "react-router-dom";

const REDIRECT_URI = `${process.env.REACT_APP_URL}/main`;

function SocialMediaLogin({ setLoginStatus, setErrorMessage, loading, setLoading }) {
  const [profile, setProfile] = useState()
  const [provider, setProvider] = useState('')
  const navigate = useNavigate();
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [logintype, setLogintype] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const planId = searchParams.get('id') ? searchParams.get('id') : -1;
  const stype = searchParams.get('type');
  const period = searchParams.get('period');
  const currency = searchParams.get('currency');

  
  useEffect(() => {
    const fetchData = async () => {
      if (profile) {
        try {
          setLoading(true); // Set loading state to true when starting the fetch
          var email;
          var firstname;
          var access_token = profile.access_token;
          setAccessToken(access_token)
          let profile_picture = null;

          if (provider === 'microsoft') {
            var idToken = profile.id_token;
            const decodedToken = decodeToken(idToken);
            email = decodedToken.email;
            setEmail(email)
            firstname = decodedToken.name;
            setFirstname(firstname)
            setLastname(null)
            setLogintype(2)

          } else if (provider === 'google') {
            const userInfoResponse = await fetch(`https://www.googleapis.com/oauth2/v2/userinfo?access_token=${profile.access_token}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${profile.access_token}`,
              },
            });

            if (!userInfoResponse.ok) {
              throw new Error('Failed to fetch user info from Google API');
            }

            const userInfo = await userInfoResponse.json();
            email = userInfo.name;
            setEmail(email)
            profile_picture = userInfo.picture;
            setProfilePicture(profile_picture)
            firstname = userInfo.given_name
            setFirstname(userInfo.given_name)
            setLastname(userInfo.family_name)
            setLogintype(1)
          }

          const publicIP = await axios.get('https://api.ipify.org?format=json');
          const ipInforesponse = await axios.get(`/ipInfo/${publicIP.data.ip}`);
          const ipInfo = ipInforesponse.data;
          const response = await axios.post('/socialMediaLogin', { email, ipInfo });
          if (response.status === 204) {
            // User not found, show terms popup
            setShowTermsPopup(true);
            return;
          }
          if (response.status === 205) {
            // User not found, show Session popup
            setShowSessionPopup(true);
            return;
          }
          const userid = response.data && response.data.userid;
          const planid = response.data && response.data.planid;
          const userplan = response.data && response.data.userplan;
          const sessionid = response.data && response.data.sessionid;
          const userdata = response.data && response.data.userdata;

          setLoginStatus(true, email, userid, planid, profile_picture, userplan, firstname, sessionid, userdata);
          if(planId > 0){
            navigate(`/accountdetails?id=${planId}&type=${stype}&period=${period}&currency=${currency}`)
          }else{
            navigate('/main');
          }

        } catch (error) {
          setErrorMessage('Invalid User ID or Password');
          // setLoginStatus(false, null, null, null, null, null, null, null);
        } finally {
          setLoading(false); // Set loading state to false after the fetch
        }
      }
    };

    fetchData();
  }, [profile]);

  const handleAcceptTerms = async () => {
      const publicIP = await axios.get('https://api.ipify.org?format=json');
      const ipInforesponse = await axios.get(`/ipInfo/${publicIP.data.ip}`)
      const ipInfo = ipInforesponse.data;
      const country = ipInfo.ipInfo.countryCode;
      const response = await axios.post('/socialMediaSignup', { email, firstname, lastname, logintype, accessToken, country, ipInfo });

      const userid = response.data && response.data.userid;
      const planid = response.data && response.data.planid;
      const userplan = response.data && response.data.userplan;
      const sessionid = response.data && response.data.sessionid;
      const userdata = response.data && response.data.userdata;

      setLoginStatus(true, email, userid, planid, profilePicture, userplan, firstname, sessionid, userdata);
      if(planId > 0){
        navigate(`/accountdetails?id=${planId}&type=${stype}&period=${period}&currency=${currency}`)
      }else{
        navigate('/main');
      }

      setShowTermsPopup(false)
  };

  const handleCreateNewSession = async () => {
      const publicIP = await axios.get('https://api.ipify.org?format=json');
      const ipInforesponse = await axios.get(`/ipInfo/${publicIP.data.ip}`)
      const ipInfo = ipInforesponse.data;
      const response = await axios.post('/socialmedialoginwithnewsession', { email, ipInfo });

      const userid = response.data && response.data.userid;
      const planid = response.data && response.data.planid;
      const userplan = response.data && response.data.userplan;
      const sessionid = response.data && response.data.sessionid;
      const userdata = response.data && response.data.userdata;
      setLoginStatus(true, email, userid, planid, profilePicture, userplan, firstname, sessionid, userdata);
      navigate('/main');

     
};
  return (
    <>
      <Modal show={showTermsPopup} onHide={() => setShowTermsPopup(false)} centered size='sm'>
        <Modal.Header closeButton={true}>
          <h6 className='mb-0 fw-semibold'>Wowleads</h6>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>
              I agree to the <a href='https://wowleads.com/terms&conditions.html' target='blank'>terms and conditions</a> and The <a href='https://wowleads.com/policy.html' target='blank'>Privacy Policy</a>
            </label>
            <div className='mt-2 d-flex justify-content-end'>
            <Button onClick={() => setShowTermsPopup(false)} className='btn-sm me-2'>
              Cancel
            </Button>
            <Button
              onClick={handleAcceptTerms}
              className={`btn-sm`}
            >
              Agree
            </Button>
          </div>
           
          </div>
        </Modal.Body>
      </Modal>
      { showSessionPopup ? (
      <Modal show={showSessionPopup} onHide={() => setShowSessionPopup(false)} centered size='md'>
    <Modal.Header closeButton={true}>
      <h6 className='mb-0 fw-semibold'>Wowleads</h6>
    </Modal.Header>
    <Modal.Body>
      <div>
        <label>
        WowLeads is currently open in another window. Would you like to close the session in the other window and open Wowleads in this window instead?
        </label>
        <div className='mt-2 d-flex justify-content-end'>
        <Button onClick={() => setShowSessionPopup(false)} className='btn-sm me-2' variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleCreateNewSession}
          className={`btn-sm`}
          variant="primary"
        >
          Proceed
        </Button>
      </div>
       
      </div>
    </Modal.Body>
  </Modal>
  ):(<>
      <div className='mb-3'>
        <LoginSocialMicrosoft
          isOnlyGetToken
          client_id={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
          redirect_uri={REDIRECT_URI}
          // onLoginStart={console.log('Microsoft oAuth initialized')}
          onResolve={({ provider, data }) => {
            setProfile(data)
            setProvider(provider)
            
          }}
          onReject={(err) => {
            console.log(err)
          }}
        >
          <button disabled={loading} style={{ width: '100%', textAlign: 'center', color: 'white' }} className="btn btn-outline-secondary btn-sm btn-dark">
            <img src='microsoft.png' alt='ms' style={{ marginRight: '8px' }} width={16} height={16} />
            Continue with Microsoft
          </button>
        </LoginSocialMicrosoft>
      </div>
      <div>
        <LoginSocialGoogle
          isOnlyGetToken
          client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          // onLoginStart={console.log('Google oAuth initialized')}
          onResolve={({ provider, data }) => {
            setProvider(provider)
            setProfile(data)
          }}
          onReject={(err) => {
            console.log(err)
          }}
        >
          <button  disabled={loading} style={{ width: '100%', textAlign: 'center', color: 'black' }} className="btn btn-outline-secondary btn-sm">
            <img src='google.png' alt='ms' style={{ marginRight: '8px' }} width={16} height={16} />
            Continue with Google
          </button>
        </LoginSocialGoogle>
      </div>
      </>
    )}
    </>
  )
}

export default SocialMediaLogin;