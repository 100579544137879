import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

const MSG_UNLOCK_LEADS = 'Please use credits to unmask the lead';

const phoneRegex = /(?<=(?=[^;]))[^;D](?=[^;]{4})/g;
const emailRegex = /(?<=(?=[^;\s]).)[^;](?=[^@;\s]*?.@)/g;

function LeadDetails(props) {
    const data = props.data.length > 0 ? props.data[0] : [];

    return (
        <div as='Row' className='h-100'>
            <div as="Row" style={{height:'60%'}} className='w-100 d-flex border'>
                <div as="Col" style={{width: '66%'}} className='h-100 border'>
                    <div as="Row" style={{height: '16%'}} className='p-1 border overflow-auto bg-primary text-white'>{data.name ? data.name : ''}</div>
                    <div as="Row" style={{height: '20%'}} className='p-1 border overflow-auto'>{data.hometitle && (data.hometitle !== '') ? data.hometitle : data.ogtitle  ? data.ogtitle : ''}</div>
                    <div as="Row" style={{height: '34%'}} className='p-1 border overflow-auto'>{data.homedescription && (data.homedescription !== '') ? data.homedescription : data.ogdescription ? data.ogdescription : ''}</div>
                    <div as="Row" style={{height: '34%'}} className='p-1 border overflow-auto'>{data.homekeywords ? data.homekeywords : ''}</div>
                </div>
                <div as="Col" style={{width: '34%'}} className='h-100 align-items-center justify-content-center border'>
                    <div as="Row" style={{height: '100%', backgroundImage: `url(${(data.ogimage && (data.ogimage !== '')) ? data.ogimage : (data.gimgurl && (data.gimgurl !== '') ? data.gimgurl : '/business.png')})`, backgroundSize: 'cover'}} ></div>
                </div>
            </div>
            <div as="Row" style={{height: '40%'}} className="w-100 overflow-auto d-flex border">
                <div as="Col" className='p-1 flex-fill'>
                    <div className='p-1 bg-primary text-white fw-bold text-nowrap'>Phone Number(s)</div>
                        <ListGroup className='text-nowrap'>
                            {(data.phones ? data.phones : '').split(';').map((c,index) => {return(<ListGroup.Item key={index}>{c}</ListGroup.Item>)})}
                        </ListGroup>
                </div>
                <div as="Col" className='p-1 flex-fill'>
                    <div className='p-1 bg-primary text-white fw-bold text-nowrap'>Email Id(s)</div>
                    <ListGroup className='text-nowrap'>
                        {(data.emails ? data.emails : '').split(';').map((c,index) => {return(<ListGroup.Item key={index}>{c}</ListGroup.Item>)})}
                    </ListGroup>
                </div>
                {(props.view === "leads") ? (
                <div as="Col" className='p-1 flex-fill'>
                    <div className='p-1 bg-primary text-white fw-bold text-nowrap'>Team</div>
                        <ListGroup className='text-nowrap'>
                            {((data.people && (data.people.substring(0,2) === '[{')) ? JSON.parse(data.people) : []).map((c,index) => {return (c.name === '') ? '' : ( 
                                <ListGroup.Item key={index}>
                                    {c.name}&nbsp;
                                    {((c.linkedin) && (c.linkedin !== '')) ? (
                                        (data.isunlocked) ? (
                                            <a rel="noreferrer" target='_blank' href={`${c.linkedin}`}><i title={'LinkedIn'} className={`bi bi-linkedin`}></i></a>
                                        ) : (
                                            <i title={'LinkedIn'} className={`bi bi-linkedin`} onClick={() => alert(MSG_UNLOCK_LEADS)}></i>
                                        )
                                    ) : ''}
                                    <br/>
                                    {((c.role) && (c.role !== '')) ? [c.role, <br/>] : ''}
                                    {((c.email) && (c.email !== '')) ? [(data.isunlocked) ? c.email : c.email?.replace(emailRegex, '*'), <br/>] : ''}
                                    {((c.phone) && (c.phone !== '')) ? [(data.isunlocked) ? c.phone : c.phone?.replace(phoneRegex, '*'), <br/>] : ''}
                                </ListGroup.Item>
                            )})}
                        </ListGroup>
                </div>
                ) : ""}
                <div as="Col" className='p-1 flex-fill'>
                    <div className='p-1 bg-primary text-white fw-bold text-nowrap'>Location(s)</div>
                        <ListGroup className='text-nowrap'>
                            {(data.pcodes ? data.pcodes : '').split(';').map((c,index)=> {return(<ListGroup.Item key={index}>{c}</ListGroup.Item>)})}
                        </ListGroup>
                </div>
            </div>
        </div>
    )
}

export default LeadDetails;